// Photo component
$(function () {
    var photosRemove = $('[data-toggle=photo][data-remove]');

    photosRemove.append('<button class="btn btn-sm btn-danger" data-toggle="remove"><i class="fa fa-trash"></i></button>');

    photosRemove.on('click', '[data-toggle=remove]', function (ev) {
        ev.stopPropagation();
        ev.preventDefault();

        var e = $(this),
            parent = e.closest('[data-remove]'),
            url = parent.attr('data-remove')
            ;

        e.prop('disabled', true);

        $.ajax({
            method: 'DELETE',
            url: url
        })
        .done(function (data, textStatus, jqXHT) {
            parent.remove();
        })
        .always(function () {
            e.prop('disabled', false);
        });
    });

    $('[data-toggle=photo][data-download]').each(function (ev) {
        var e = $(this),
            url = e.attr('data-download')
            ;

        if (url) e.append('<a class="btn btn-sm btn-primary" href="' + url + '" target="_blank" data-toggle="download" onclick="event.stopPropagation();"><i class="fa fa-download"></i></a>');
    });

    $('[data-toggle=photo][data-remote]:not([data-gallery])').attr('data-gallery', 'auto-grouping');

    $(document).on('click', '[data-toggle=photo][data-remote]', function () {
        $(this).ekkoLightbox({ alwaysShowClose: true, showArrows: true });
    });

    function reindex(root) {
        root.find('[data-toggle=photo]').each(function (i, e) {
            $(e).find('[name]').each(function (ii, ee) {
                var input = $(ee),
                    name = input.attr('name').replace(/\[\d*]/g, '[' + i + ']')                ;
                input.attr('name', name);
            });
        });
    }

    $('.photos').each(function () {
        var photos = $(this);

        photos.sortable({
            containment: 'parent',
            tolerance: 'pointer',
            cursor: 'move',
            zindex: 1000,
            update: function(event, ui) {
                reindex(photos);
            }
        });
    });
});